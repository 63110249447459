
const ROUTES = {
    DockerDetails: "DockerDetails",
    ApiKeyDetails: "ApiKeyDetails",
    PaymentSuccess: "PaymentSuccess",
    PaymentError: "PaymentError",
    Verify: "Verify",
    CreatePassword: "CreatePassword",
    Login: "Login",
    Login2FA: "Login2FA",
    ShortLink: "ShortLink",
    EmailConfirmation: "EmailConfirmation",
    Register: "Register",
    ResetPassword: "ResetPassword",
    ForgotPassword: "ForgotPassword",
    ReactivateAccount: "ReactivateAccount",
    Dashboard: "Dashboard",
    Projects: "Projects",
    Onboarding: "Onboarding",
    FeelGPTRoot: "FeelGPTRoot",
    FeelGPT: "FeelGPT",
    FeelGPTMain: "FeelGPTMain",
    FeelGPTEdit: "FeelGPTEdit",
    FeelGPTResultEdit: "FeelGPTResultEdit",
    EmotionalDiamondRoot: "EmotionalDiamondRoot",
    EmotionalDiamond: "EmotionalDiamond",
    EmotionalDiamondMain: 'EmotionalDiamondMain',
    EmotionalDiamondResult: "EmotionalDiamondResult",
    EmotionalDiamondTicketEdit: "EmotionalDiamondTicketEdit",
    PrintPdfLayout: "PrintPdfLayout",
    FeelGPTResult: "FeelGPTResult",
    FeelGPTResultPrint: "FeelGPTResultPrint",
    DiscoveryReportRoot: 'DiscoveryReportRoot',
    DiscoveryReport: 'DiscoveryReport',
    DiscoveryReportMain: 'DiscoveryReportMain',
    DiscoveryReportResult: 'DiscoveryReportResult',
    DiscoveryReportTicketEdit: "DiscoveryReportTicketEdit",
    ApptoneQuestionnairesRoot: "ApptoneQuestionnairesRoot",
    ApptoneQuestionnaires: "ApptoneQuestionnaires",
    ApptoneQuestionnaireEdit: "ApptoneQuestionnaireEdit",
    ApptoneQuestionnaireView: "ApptoneQuestionnaireView",
    ApptoneQuestionnaireAnalysisReport: 'ApptoneQuestionnaireAnalysisReport',
    ReportsSites: "ReportsSites",
    ReportSiteDetails: "ReportSiteDetails",
    ReportApiKeyDetails: "ReportApiKeyDetails",
    ReportDockerDetails: "ReportDockerDetails",
    ReportsApplications: "ReportsApplications",
    ReportsDockers: "ReportsDockers",
    ReportsErrors: "ReportsErrors",
    AppTypeHelp: "AppTypeHelp",
    SiteForm: "SiteForm",
    BillingDownloadProformaInvoice: "BillingDownloadProformaInvoice",
    BillingOverview: "BillingOverview",
    BillingPayment: "BillingPayment",
    PersonalInformation: "PersonalInformation",
    OrganizationInformation: "OrganizationInformation",
    OrganizationDetails: "OrganizationDetails",
    SettingsUsers: "SettingsUsers",
    AnalyzeNowAPIKeys: "AnalyzeNowAPIKeys",
    AccountSecurity: "AccountSecurity",
    SiteDetails: "SiteDetails",
    SiteNew: "SiteNew",
    DocumentationPage: "DocumentationPage",
    SettingsNotifications: "SettingsNotifications",
    CloudAnalysis: "CloudAnalysis",
    DockerErrors: "DockerErrors",
    SettingsSecurity: "SettingsSecurity",
    NotificationList: "NotificationList",
    TermOfUse: "TermOfUse",
    PrivacyPolicy: "PrivacyPolicy",

    DistributorsRoot: "DistributorsRoot",
    DistributorsDashboard: "DistributorsDashboard",
    DistributorsCommissionsManagement: "DistributorsCommissionsManagement",
    DistributorsBankDetails: "DistributorsBankDetails",
    DistributorsLinkAccount: "DistributorsLinkAccount",

    DataProcessingAgreement: "DataProcessingAgreement",
    CustomerSupportCasesRoot: "CustomerSupportCasesRoot",
    CustomerSupportCases: "CustomerSupportCases",
    CustomerSupportCaseManage: "CustomerSupportCaseManage",
    CustomerSupportCasesManagement: "CustomerSupportCasesManagement",
    CustomerSupportCasesManagementRoot: "CustomerSupportCasesManagementRoot",
    CustomerSupportCaseManagement: "CustomerSupportCaseManagement",
    CustomerSupportCaseManagementDetails: "CustomerSupportCaseManagementDetails",
    // Admin
    AdminDistributors: "AdminDistributors",
    AdminDistributorsWithdrawals: "AdminDistributorsWithdrawals",
    AdminOrganizations: "AdminOrganizations",
    AdminThirdPartyUsage: "AdminThirdPartyUsage",
    PaymentsReport: "PaymentsReport",
    AdminOrganizationDetails: "AdminOrganizationDetails",
    AdminSiteDetails: "AdminSiteDetails",
    AdminApiKeyDetails: "AdminApiKeyDetails",
    AdminApiKeyReport: "AdminApiKeyReport",
    AdminDockerDetails: "AdminDockerDetails",
    AdminCustomerSupportDetails: "AdminCustomerSupportDetails",

    SettingsGeneral: "SettingsGeneral",
    NotificationTemplatesRoot: "NotificationTemplatesRoot",
    NotificationTypeEdit: "NotificationTypeEdit",
    ApptoneMessageTemplatesRoot: "ApptoneMessageTemplatesRoot",
    ApptoneMessageTemplatesEdit: "ApptoneMessageTemplatesEdit",
    SystemGroup: "SystemGroup",
    DocumentationRoot: "DocumentationRoot",
    TranslationRoot: "TranslationRoot",
    Documentation: "Documentation",
    DocumentationEdit: "DocumentationEdit",
    DocumentationCreate: "DocumentationCreate",
    DocumentationGroup: "DocumentationGroup",
    DocumentationGroupRoot: "DocumentationGroupRoot",
    DocumentationGroupEdit: "DocumentationGroupEdit",
    DocumentationGroupCreate: "DocumentationGroupCreate",
    DocumentationImages: "DocumentationImages",
    DockerVersionsRoot: "DockerVersionsRoot",
    LicenseKeys: "LicenseKeys",
    CreditKeys: "CreditKeys",
    ReconfigurationKeys: "ReconfigurationKeys",
    AdminDockerRegressionRoot: "AdminDockerRegressionRoot",
    AdminDockerRegressionTest: "AdminDockerRegressionTest",
    AdminDockerRegressionTestsEdit: "AdminDockerRegressionTestsEdit",
    AdminResearchRoot: "AdminResearchRoot",
    AdminProjectsRoot: "AdminProjectsRoot",
    AdminProjectsList: "AdminProjectsList",
    AdminProjectsEdit: "AdminProjectsEdit",
    AdminProjectsFiles: "AdminProjectsFiles",
    AdminProjectsStats: "AdminProjectsStats",
    AppAnalyzeFile: "AppAnalyzeFile",
    AnalyzeNowRoot: "AnalyzeNowRoot",
    AnalyzeNow: "AnalyzeNow",
    AnalyzeNowEdit: "AnalyzeNowEdit",
    AdminUploadFiles: "AdminUploadFiles",
    AdminEmailBroadcast: "AdminEmailBroadcast",
    WebApptone: "WebApptone",
    WebApptoneMitsui: "WebApptoneMitsui",

    MANAGEMENT: {
        ROLES: "MANAGEMENT_ROLES",
        SETTINGS: "SETTINGS",
        ACCOUNT_TIERS: {
            LIST: "MANAGEMENT_ACCOUNT_TIERS",
            EDIT: "MANAGEMENT_ACCOUNT_TIERS_EDIT",
        },
        APPLICATION_TYPES: {
            LIST: "MANAGEMENT_APPLICATION_TYPES",
            EDIT: "MANAGEMENT_APPLICATION_TYPES_EDIT",
        },
        MANUAL_PAYMENT_TYPES: {
            LIST: "MANAGEMENT_MANUAL_PAYMENT_TYPES",
            EDIT: "MANAGEMENT_MANUAL_PAYMENT_TYPES_EDIT",
        },
        PI_TEMPLATE: "MANAGEMENT_PI_TEMPLATE",
        PERMISSION_LEVELS: "MANAGEMENT_PERMISSION_LEVELS",
        NOTIFICATION_TEMPLATES: {
            LIST: "MANAGEMENT_NOTIFICATION_TEMPLATES",
            EDIT: "MANAGEMENT_NOTIFICATION_TEMPLATES_EDIT",
        },
        APPTONE_MESSAGE_TEMPLATES: {
            LIST: "MANAGEMENT_APPTONE_MESSAGE_TEMPLATES",
            EDIT: "MANAGEMENT_APPTONE_MESSAGE_TEMPLATES_EDIT",
        },
        NOTIFICATION_TYPES: "MANAGEMENT_NOTIFICATION_TYPES",
        SYSTEM_TEXT: {
            LIST: "MANAGEMENT_SYSTEM_TEXT",
            CREATE: "MANAGEMENT_SYSTEM_TEXT_CREATE",
            UPDATE: "MANAGEMENT_SYSTEM_TEXT_UPDATE",
        },
        SYSTEM_TIP: {
            LIST: "MANAGEMENT_SYSTEM_TIP",
            UPDATE: "MANAGEMENT_SYSTEM_TIP_UPDATE",
        },
        DOCUMENTATION: {
            LIST: "MANAGEMENT_DOCUMENTATION",
            CREATE: "MANAGEMENT_DOCUMENTATION_CREATE",
            UPDATE: "MANAGEMENT_DOCUMENTATION_UPDATE",
        },
        DOCUMENTATION_GROUPS: {
            LIST: "MANAGEMENT_DOCUMENTATION_GROUPS",
            CREATE: "MANAGEMENT_DOCUMENTATION_GROUPS_CREATE",
            UPDATE: "MANAGEMENT_DOCUMENTATION_GROUPS_UPDATE",
        },
        DOCKER_VERSIONS: {
            LIST: "MANAGEMENT_DOCKER_VERSIONS",
            CREATE: "MANAGEMENT_DOCKER_VERSIONS_CREATE",
            UPDATE: "MANAGEMENT_DOCKER_VERSIONS_UPDATE",
        },
        AFFILIATE_COUPON_TYPE: {
            LIST: "MANAGEMENT_AFFILIATE_COUPON_TYPES"
        },
        TRANSLATION: {
            LIST: "MANAGEMENT_TRANSLATION",
        },
    },

    RegisterDetails: 'RegisterDetails',
    AffiliatePaymentPolicy: 'AffiliatePaymentPolicy',
    Payouts: 'Payouts',
};

function checkUserPermission(user, route) {
    const requiredPermissions = (route && route.meta && route.meta.permissions) || null;

    if (!requiredPermissions)
        return true;

    if (!user)
        return false;

    if(route.meta.emailVerified && !user.emailVerified)
        return false;

    const userPermissions = user.permissions || {}

    return Object.keys(requiredPermissions).every(k => requiredPermissions[k] && userPermissions[k]);
}

function checkAllowToViewOnMobile(route) {
    return !!route?.meta?.allowMobile;
}

let routesByName = null;

function getRoutesByName(router) {
    if (!routesByName) {
        routesByName = router.getRoutes().reduce((a,r) => ({...a, [r.name]: r}), {});
    }
    return routesByName;

}

function updateSiteReportBreadcrumbs (t, items) {
    const routeMap = {
        [ROUTES.Projects]: ROUTES.ReportsSites,
        [ROUTES.SiteDetails]: ROUTES.ReportSiteDetails,
        [ROUTES.ApiKeyDetails]: ROUTES.ReportApiKeyDetails,
    };
    return [
        {
            "name": t('common.reports'),
            "link": "#",
            "active": false
        },
        ...items.map(i => routeMap[i.link?.name] ? ({...i, link: {...i.link, name: routeMap[i.link?.name]}}) : i)
    ];
}

export {
    ROUTES,
    checkUserPermission,
    getRoutesByName,
    updateSiteReportBreadcrumbs,
    checkAllowToViewOnMobile,
}
